.signin-form,
.signup-form,
.remind-password-form {
  padding: -webkit-calc(30px + 20px) 0;
  padding: calc(30px + 20px) 0;
}
.signin-form label,
.signup-form label,
.remind-password-form label {
  font-size: 14px;
}
.signin-form__info,
.signup-form__info,
.remind-password-form__info {
  margin-top: 20px;
  margin-bottom: -webkit-calc(30px + 20px);
  margin-bottom: calc(30px + 20px);
  text-align: center;
}
.signin-form__info,
.signup-form__info,
.remind-password-form__info,
.signin-form__info-signin,
.signup-form__info-signin,
.remind-password-form__info-signin,
.signin-form__info-signup,
.signup-form__info-signup,
.remind-password-form__info-signup {
  font-size: 14px;
}
.signin-form__info-signin,
.signup-form__info-signin,
.remind-password-form__info-signin,
.signin-form__info-signup,
.signup-form__info-signup,
.remind-password-form__info-signup {
  padding: 0;
  height: auto;
}
.signin-form__info-signin span,
.signup-form__info-signin span,
.remind-password-form__info-signin span,
.signin-form__info-signup span,
.signup-form__info-signup span,
.remind-password-form__info-signup span {
  text-decoration: underline;
}
.signin-form__info-signin:hover span,
.signup-form__info-signin:hover span,
.remind-password-form__info-signin:hover span,
.signin-form__info-signup:hover span,
.signup-form__info-signup:hover span,
.remind-password-form__info-signup:hover span,
.signin-form__info-signin:focus span,
.signup-form__info-signin:focus span,
.remind-password-form__info-signin:focus span,
.signin-form__info-signup:focus span,
.signup-form__info-signup:focus span,
.remind-password-form__info-signup:focus span {
  text-decoration: none;
}
.signin-form__fields,
.signup-form__fields,
.remind-password-form__fields {
  margin-bottom: -webkit-calc(30px + 20px);
  margin-bottom: calc(30px + 20px);
}
.signin-form__fields .ant-input-suffix,
.signup-form__fields .ant-input-suffix,
.remind-password-form__fields .ant-input-suffix {
  font-size: 22px;
}
.signin-form__control,
.signup-form__control,
.remind-password-form__control {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.signin-form__control-submit,
.signup-form__control-submit,
.remind-password-form__control-submit {
  margin-bottom: 20px;
}
.signin-form__control-remind-password,
.signup-form__control-remind-password,
.remind-password-form__control-remind-password {
  padding: 0;
  height: auto;
  font-size: 12px;
}
.signin-form__control-remind-password span,
.signup-form__control-remind-password span,
.remind-password-form__control-remind-password span {
  text-decoration: underline;
}
.signin-form__control-remind-password:hover span,
.signup-form__control-remind-password:hover span,
.remind-password-form__control-remind-password:hover span,
.signin-form__control-remind-password:focus span,
.signup-form__control-remind-password:focus span,
.remind-password-form__control-remind-password:focus span {
  text-decoration: none;
}
.signup-form__accept {
  font-size: 12px;
}
.signup-form__accept a {
  color: inherit;
  text-decoration: underline;
}
.signup-form__accept a:hover,
.signup-form__accept a:focus {
  text-decoration: none;
}
.signup-form__control {
  margin-top: 50px;
}
.signup-form__success {
  margin: 40px 0 0;
  text-align: center;
  font-size: 14px;
}
.signup-form .warning {
  margin: -20px 0 30px;
}
.remind-password-form__control {
  margin-top: 50px;
}
